/* eslint-disable no-underscore-dangle */
import * as fb from '@/firebase';
import _ from 'lodash';

const getters = {
  current: (state) => state.current,
};
const actions = {
  setCurrent: (context, payload) => {
    context.commit('setCurrent', payload);
  },
  _get: (context) => {
    const ref = fb.db.collection('/appSettings');
    const settings = {};
    return ref
      .get()
      .then((res) => {
        res.forEach(async (doc) => {
          settings[doc.id] = doc.data();
        });
        context.commit('setCurrent', _.cloneDeep(settings));
        return _.cloneDeep(settings);
      })
      .catch((error) => {
        console.log(error);
        const e = Object.assign(error, {
          message: 'Error finding App Settings',
        });
        throw e;
      });
  },
  get: (context) =>{
    const settings = {
      general:{
        eventUrl: "https://live.livecasthq.com",
        landingUrl: "https://register.livecasthq.com",
      },
      streamSettings:{
        embedUrl: "https://embed.livecasthq.com",
        playerUrl: "https://lcdn52.azureedge.net/platform/",
      }
    }
    context.commit('setCurrent', _.cloneDeep(settings));
    return _.cloneDeep(settings);
  }
};
const mutations = {
  setCurrent: (state, payload) => {
    state.current = payload;
  },
};

const state = {
  current: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
