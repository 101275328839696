import Vue from 'vue';
import {
  ModalPlugin, FormSelectPlugin, ToastPlugin, FormGroupPlugin,
} from 'bootstrap-vue';

import { i18n } from '@/utils/i18n-setup';
import './registerServiceWorker';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueCookies from 'vue-cookies';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store';

import App from './App.vue';

Vue.config.productionTip = false;

Vue.use(ModalPlugin);
Vue.use(FormSelectPlugin);
Vue.use(ToastPlugin);
Vue.use(FormGroupPlugin);
Vue.use(VueMoment, {
  moment,
});
Vue.use(VueCookies);

const $vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

export default $vm;
