const messages = {
  en: {
    cancel: 'Cancel',
    send: 'Send',
    sending: 'Sending...',
    name: 'Name',
    okey: 'Okey',
    page_language: 'Website Language',
    type_your_question: 'Please type your question',
    this_event_is_not_started: 'This event is not started yet.',
    this_event_completed: 'This event has been concluded.',
    your_question_has_been_sent: 'Your question has been sent.',
    registration_required:
      'Registration is required for this event. Redirecting to registration page.',
  },
};

export default messages;
